import { Grid } from "@material-ui/core";
import { FC } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Label,
  Tooltip,
} from "recharts";
import { CheckIn } from "../../../types/CheckIn";
import { getCalories } from "../../../share/utils/getCalories";

interface Props {
  checkIns: CheckIn[];
}

const options: any = {
  month: "2-digit",
  day: "numeric",
};

export const WeightDateGraph: FC<Props> = ({ checkIns }) => {
  const data = checkIns.map((checkIn) => {
    const { date, weight, protein, fat, carb } = checkIn;

    const calories = getCalories({ protein, fat, carb });

    const formattedDate = new Date(date).toLocaleString("en-us", options);

    return { date: formattedDate, weight, calories };
  });

  return (
    <Grid item xs={12} sm={5}>
      <ResponsiveContainer width="100%" height={200}>
        <LineChart data={data}>
          <Tooltip
            //@TODO abstract this bad boy
            content={(value) => {
              const payload = value?.payload?.[0]?.payload;

              if (!payload) {
                return <></>;
              }

              return (
                <div
                  style={{
                    backgroundColor: "white",
                    border: "1px solid rgb(204, 204, 204)",
                    padding: "10px",
                  }}
                >
                  {Object.entries(payload).map(([key, value]) => (
                    <p
                      key={key}
                      style={{
                        marginBlockStart: "1em",
                        marginBlockEnd: "1em",
                        color: "rgb(136, 132, 216)",
                        padding: "4px 0",
                        textTransform: "capitalize",
                      }}
                    >
                      {key} : {value}
                    </p>
                  ))}
                </div>
              );
            }}
          />
          <Line type="monotone" dataKey="weight" stroke="#8884d8" />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis dataKey="date" />
          <YAxis
            domain={[
              (datamin: number) => Math.floor(datamin * 0.99),
              (datamax: number) => Math.ceil(datamax * 1.01),
            ]}
          >
            <Label
              value="Weight"
              offset={0}
              angle={-90}
              position="insideLeft"
            />
          </YAxis>
        </LineChart>
      </ResponsiveContainer>
    </Grid>
  );
};
