import axios from "axios";

type ENV = 'prod' | 'qa' | 'dev' | 'local'

const getBaseUrl = (): string => {
  const env = (process.env.REACT_APP_ENV ?? 'dev') as ENV;

  if (env === 'prod') {
    return 'https://coacher-api.developer.ge'
  }

  if (env === 'local') {
    return 'http://localhost:8085'
  }

  return `https://coacher-api-${env}.developer.ge`
}

export const axiosInstance = (token: string) => {
  return axios.create({
    baseURL: getBaseUrl(),
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};
