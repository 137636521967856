import { useAuth0 } from "@auth0/auth0-react";
import {
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { UserApi } from "api/user/userApi";
import { AxiosInstance } from "axios";
import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { ToastProps } from "types/Toast";
import { MeasurementUnits } from "types/MeasurementUnits";

interface Props {
  instance: AxiosInstance;
}
export const CreateClient: FC<Props> = ({ instance }) => {
  const { user } = useAuth0();
  const { push } = useHistory();

  const userApi = new UserApi(instance);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [measurementUnit, setMeasurementUnit] = useState<MeasurementUnits>("LB");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [toast, setToast] = useState<ToastProps>();

  const makeHandleChange = (setFunction: (value: string) => void) => (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFunction(e.currentTarget.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setSubmitting(true);

    e.preventDefault();

    const payload = {
      firstName,
      lastName,
      email,
      measurementUnit
    };

    const response = await userApi?.createClient(payload);

    if (response.error) {
      setToast({
        message: "Error: user not created",
        severity: "error",
      });
    } else {
      setToast({
        message: "User created successfully",
        severity: "success",
      });
    }

    setSubmitting(false);
  };

  const roles = user?.["http://nutrition-tracker-api.com/roles"];

  if (roles.includes("User") && roles.length === 1) {
    push("/");
  }

  return (
    <Grid container xs={6} item spacing={4} direction="column">
      <Snackbar
        open={!!toast?.message}
        autoHideDuration={6000}
        onClose={() => setToast({ message: "" })}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <Alert
          onClose={() => setToast({ message: "" })}
          severity={toast?.severity}
        >
          {toast?.message}
        </Alert>
      </Snackbar>
      <Grid item>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="First Name"
            type="text"
            value={firstName}
            onChange={makeHandleChange(setFirstName)}
          />
          <TextField
            fullWidth
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={makeHandleChange(setLastName)}
          />
          <TextField
            fullWidth
            type="text"
            placeholder="Email"
            value={email}
            onChange={makeHandleChange(setEmail)}
          />
          <Select
            onChange={(event) =>
              setMeasurementUnit(event.target.value as MeasurementUnits)
            }
            placeholder="Measurement"
            value={measurementUnit}
          >
            <MenuItem value="KG">KG</MenuItem>
            <MenuItem value="LB">LB</MenuItem>
          </Select>

          <Grid item>
            <Button
              disabled={!firstName || !lastName || !email || submitting}
              fullWidth
              color="primary"
              variant="contained"
              type="submit"
            >
              Submit
            </Button>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};
