import { Grid } from "@material-ui/core";
import { FC } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Label,
  LabelList,
  Tooltip,
} from "recharts";
import { CheckIn } from "../../../types/CheckIn";
import { getCalories } from "../../../share/utils/getCalories";

const options: any = {
  month: "2-digit",
  day: "numeric",
};

interface Props {
  checkIns: CheckIn[];
}

export const WeightCalorieGraph: FC<Props> = ({ checkIns }) => {
  const data = checkIns
    .map((checkIn) => {
      const { protein, fat, carb, date } = checkIn;
      const calories = getCalories({ protein, fat, carb });

      const formattedDate = new Date(date).toLocaleString("en-us", options);

      return { calories, date: formattedDate };
    })
    .filter((checkIn) => !!checkIn.calories);

  return (
    <Grid item xs={12} sm={5}>
      <ResponsiveContainer width="100%" height={200}>
        <LineChart data={data}>
          <Tooltip />
          <Line type="monotone" dataKey="calories" stroke="#8884d8" />
          <LabelList dataKey="calories" position="insideTop" />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis dataKey="date" />

          <YAxis
            domain={[
              (datamin: number) => Math.floor(datamin * 0.99),
              (datamax: number) => Math.ceil(datamax * 1.01),
            ]}
          >
            <Label
              value="Calories"
              offset={0}
              angle={-90}
              position="insideLeft"
            />
          </YAxis>
        </LineChart>
      </ResponsiveContainer>
    </Grid>
  );
};
