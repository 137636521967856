import { useAuth0 } from "@auth0/auth0-react";
import { Loading } from "components/Loading";
import { useEffect, useState } from "react";

interface Props {
    children(token: string): JSX.Element;
}

export const AuthWrapper: React.FC<Props> = ({ children }) => {
    const [token, setToken] = useState<string>();

    const {
        loginWithRedirect,
        isLoading,
        isAuthenticated,
        getAccessTokenSilently,
    } = useAuth0();

    useEffect(() => {
        if (isAuthenticated && !token) {
            getAccessTokenSilently().then((_token) => {
                setToken(_token);
            });
        }
    }, [getAccessTokenSilently, isAuthenticated, token]);


    if (isLoading) {
        return <Loading />;
    }

    if (!isAuthenticated) {
        loginWithRedirect();

        return <Loading />;
    }

    if (!token) {
        return <Loading />;
    }


    return children(token);
};