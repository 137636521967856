import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Grid,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import { FC, useState } from "react";
import { ToastProps } from "../../../types/Toast";
import { useQueryClient } from "react-query";
import { UserApi } from "api/user/userApi";
import { CheckIn } from "types/CheckIn";
import { DateTime } from "luxon";

interface Props {
  isOpen: boolean;
  onClose(): void;
  userId: number;
  userApi: UserApi;
  currentCheckInData?: CheckIn;
}

const defaultCheckInData: CheckIn = {
  date: DateTime.now().toFormat('yyyy-MM-dd')
};

export const AddWeightModal: FC<Props> = ({ isOpen, onClose, userId, userApi, currentCheckInData = defaultCheckInData }) => {
  const { weightId, date: _date, weight: _weight } = currentCheckInData;

  const [date, setDate] = useState(_date);
  const [weight, setWeight] = useState(_weight ?? "");
  const [toast, setToast] = useState<ToastProps>();

  const queryClient = useQueryClient();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const payload = {
      weight: weight as number,
      userId: userId,
      weightId: Number(weightId),
      date: new Date(date).toJSON(),
    };

    const weightCall = async () => {
      if (weightId) {
        await userApi!.updateWeight(payload);
      } else {
        await userApi?.createWeight({
          date: new Date(date).toJSON(),
          userId,
          weight: Number(weight),
        });
      }
    };

    try {
      await weightCall();

      queryClient.invalidateQueries("metrics");

      setToast({
        message: "Weight updated successfully",
        severity: "success",
      });

      onClose();
    } catch {
      setToast({
        message: "Error: weight not updated",
        severity: "error",
      });
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <Snackbar
        open={!!toast?.message}
        autoHideDuration={6000}
        onClose={() => setToast({ message: "" })}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <Alert
          onClose={() => setToast({ message: "" })}
          severity={toast?.severity}
        >
          {toast?.message}
        </Alert>
      </Snackbar>
      <Grid container justifyContent="space-between" alignItems="center">
        <DialogTitle id="form-dialog-title">Check in</DialogTitle>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <TextField
                disabled={!!weightId}
                onChange={(e) => setDate(e.target.value)}
                value={date}
                fullWidth
                type="date"
                label="Date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                id="weight"
                label="Weight"
                type="text"
                value={weight}
                onChange={(e) => {
                  if (!isNaN(Number(e.target.value))) {
                    setWeight(e.target.value);
                  }
                }}
              />
            </Grid>

            <Grid item>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                type="submit"
              >
                Check In
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
