import { useAuth0 } from "@auth0/auth0-react";

const redirectUrl =
  process.env.NODE_ENV === "production"
    ? "https://www.nutritioncoach.io"
    : "http://localhost:3000";

export const useLogout = () => {
  const { logout } = useAuth0();

  return () => logout({ returnTo: redirectUrl });
};
