import { Box, Grid, MenuItem, Select, Typography } from "@material-ui/core";
import { FC, useState } from "react";
import { CheckIn } from "../../../types/CheckIn";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { MeasurementUnits } from "../../../types/MeasurementUnits";
import { DateTime } from "luxon";

interface Props {
  measurementUnit: MeasurementUnits;
  weighIns: CheckIn[];
}

const labelToCheckinLength = {
  lastWeek: 7,
  lastMonth: 30,
  history: undefined,
};

type FilterTypes = keyof typeof labelToCheckinLength;

export const WeightChangeMetric: FC<Props> = ({ measurementUnit, weighIns }) => {
  const [filter, setFilter] = useState<FilterTypes>("history");

  const checkInLength = labelToCheckinLength[filter];

  const firstDate = DateTime.fromISO(weighIns[0].date).minus({ days: checkInLength });
  const filteredWeighIns = !checkInLength ? weighIns : weighIns.filter(({ date }) => {
    return DateTime.fromISO(date) > firstDate;
  });

  const handleChange = (
    event: React.ChangeEvent<{
      value: unknown;
    }>
  ) => {
    setFilter(event.target.value as FilterTypes);
  };

  const difference =
    filteredWeighIns[filteredWeighIns.length - 1].weight! -
    filteredWeighIns[0].weight!;

  return (
    <Box border="1px solid black" padding={2}>
      <Grid container direction="column">
        <Box padding={2}>
          <Grid item container justifyContent="center" spacing={5} alignItems="center">
            <Grid item>
              <Typography>Weight change for:</Typography>
            </Grid>
            <Grid item>
              <Select
                autoWidth
                value={filter}
                variant="filled"
                onChange={handleChange}
                style={{ width: "150px" }}
              >
                <MenuItem value="lastWeek">Past week</MenuItem>
                <MenuItem value="lastMonth">Past 30 days</MenuItem>
                <MenuItem value="history">Entire History</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Box>
        <Grid container item alignItems="center" spacing={2} justifyContent="center">
          <Grid item>
            <Typography variant="h3">
              {Math.abs(difference).toFixed(2)}
              {measurementUnit}
            </Typography>
          </Grid>
          <Grid item>
            {difference > 0 && <ArrowDownwardIcon fontSize="large" />}
            {difference < 0 && <ArrowUpwardIcon fontSize="large" />}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
