import { Box } from "@material-ui/core";

export const MetricPlaceholder: React.FC = () => {
    return (
        <Box display='flex' justifyContent='center' marginBottom='50px'>
            <Box border="1px solid black" textAlign='center' height={100} alignContent='middle' padding={2}>
                Add weigh ins to start seeing metrics
            </Box>
        </Box>
    );
};