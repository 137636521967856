import { Grid, Select, MenuItem, FormHelperText, Typography, Link, Menu, Button } from "@material-ui/core";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import { AverageChangeMetric } from "./components/AverageChangeMetric";
import { CheckInTable } from "./components/CheckInTable";
import { WeightCalorieGraph } from "./components/CalorieDateGraph";
import { WeightChangeMetric } from "./components/WeightChangeMetric";
import { WeightDateGraph } from "./components/WeightDateGraph";
import { UserApi } from "../../api/user/userApi";
import { Metrics } from "../../types/Metric";
import { Loading } from "../../components/Loading";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { Coach } from "types/Coach";
import { AxiosInstance } from "axios";
import { ModalKeys } from "AppRoutes";
import { CheckIn } from "types/CheckIn";
import { MetricPlaceholder } from "pages/home/components/MetricPlaceholder";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

interface Props {
  instance: AxiosInstance;
  isUserCoach: boolean;
  clientId?: number;
  setClientId(clientId: number): void;
  setOpenModalKey(newKey?: ModalKeys): void;
  setDefaultCheckInData(metricsData?: CheckIn): void;
  isCurrentUserSelected: boolean;
}

export const HomePage: FC<Props> = ({ setOpenModalKey, isCurrentUserSelected, instance, isUserCoach, clientId, setClientId, setDefaultCheckInData }) => {
  const [leftMenuAnchor, setLeftMenuAnchor] = useState<HTMLElement | null>(null);

  const userApi = new UserApi(instance);


  const { data: coachData } = useQuery<Coach>(
    "coach",
    () => userApi.getCurrentCoachUser(),
    {
      enabled: isUserCoach,
    }
  );

  const { data: metrics } = useQuery<Metrics>(
    ["metrics", [clientId]],
    () => userApi.getMetrics(clientId!.toString()),
    { enabled: !!clientId }
  );

  useQuery<Metrics>(["metrics", [clientId]], () => userApi.getCurrentUser(), {
    enabled: !coachData?.clients?.length,
  });

  useEffect(() => {
    if (!clientId && coachData?.clients?.length) {
      setClientId(coachData.clients[0].userId);
    }
  }, [clientId, coachData, setClientId]);

  if (!metrics) {
    return <Loading />;
  }

  const { metricsPerDate, user } = metrics;

  const mappedCheckins = Object.entries(metricsPerDate)
    .map(([date, metric]) => {
      const { nutrition, weightList } = metric;

      const newDate = DateTime.fromISO(date).toFormat('yyyy-MM-dd');

      const lastWeighIn = weightList?.[weightList.length - 1];

      const returnObj = {
        date: newDate,
        weight: lastWeighIn?.weight,
        weightId: lastWeighIn?.weightId,
        userId: user.userId,
        carb: nutrition?.carb,
        fat: nutrition?.fat,
        protein: nutrition?.protein,
        maintenance: nutrition?.isMaintenance,
        nutritionId: nutrition?.nutritionId,
      };

      return returnObj;
    })
    .sort((a, b) => +new Date(a.date) - +new Date(b.date));

  const weighIns = mappedCheckins
    .filter((checkin) => checkin.weight)
    .sort((a, b) => +new Date(b.date!) - +new Date(a.date!));

  const doesCoachHaveClients = !!coachData?.clients?.length;

  const noDataMessage = isCurrentUserSelected ? "Click on the plus icon to start tracking" : "When this client checks in, data will start appearing here";

  return (
    <Grid container direction="column" spacing={6}>
      <Grid item>
        {
          isCurrentUserSelected && (
            <Button color="primary" onClick={(e) => setLeftMenuAnchor(e.currentTarget)} variant="contained" endIcon={<AddCircleOutlineIcon />}>
              Add Entry
            </Button>
          )
        }
      </Grid>
      {coachData?.clients && !!clientId && (
        <Grid container item zeroMinWidth>
          <Grid item xs={3} justifyContent="center" container>
            <Select
              fullWidth
              variant="outlined"
              value={clientId}
              onChange={(e) => setClientId(e.target.value as number)}
            >
              {coachData.clients.map((client) => (
                <MenuItem value={client.userId} key={client.userId}>
                  {client.firstName} {client.lastName}
                </MenuItem>
              ))}
              <MenuItem value={coachData.userId} key={coachData.userId}>
                {coachData.firstName} {coachData.lastName} (Current User)
              </MenuItem>
            </Select>
            <FormHelperText>Select a client</FormHelperText>
          </Grid>
        </Grid>
      )}
      {/* <Grid item>
        <GoalsDisplay cut={goals.cut} maintenance={goals.maintenance} />
      </Grid> */}
      {isUserCoach && !doesCoachHaveClients && (
        <Typography>
          You currently have no clients assigned to you.  Go to <Link href="/coach">Create user</Link> to add a user
        </Typography>
      )}
      {mappedCheckins.length === 0 ? (
        <Grid container justifyContent="center">
          <Typography>{noDataMessage}</Typography>
        </Grid>
      ) :
        <>
          {
            weighIns.length > 0 ?
              (
                <Grid item container justifyContent="space-around">
                  <WeightChangeMetric
                    weighIns={weighIns}
                    measurementUnit={user.measurementUnit}
                  />
                  <AverageChangeMetric
                    weighIns={weighIns}
                    measurementUnit={user.measurementUnit}
                  />
                </Grid>
              ) : <MetricPlaceholder />
          }

          <CheckInTable
            isCurrentUserSelected={isCurrentUserSelected}
            setOpenModalKey={setOpenModalKey}
            setDefaultCheckInData={setDefaultCheckInData}
            checkIns={[...mappedCheckins].reverse()}
            measurementUnit={user.measurementUnit}
          />

          <Grid item container spacing={2} justifyContent="space-between">
            <WeightDateGraph checkIns={mappedCheckins} />
            <WeightCalorieGraph checkIns={mappedCheckins} />
          </Grid>
        </>
      }
      {isCurrentUserSelected && (
        <Menu
          id="simple-menu"
          anchorEl={leftMenuAnchor}
          keepMounted
          open={Boolean(leftMenuAnchor)}
          onClose={() => setLeftMenuAnchor(null)}
        >
          <MenuItem onClick={() => {
            setOpenModalKey("checkIn");
          }}
          >
            Check in
          </MenuItem>
          <MenuItem onClick={() => {
            setOpenModalKey("addWeight");
          }}
          >
            Update Weight
          </MenuItem>
        </Menu>
      )}
    </Grid>
  );
};
