import { Box, Grid, MenuItem, Select, Typography } from "@material-ui/core";
import { FC, useState } from "react";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { MeasurementUnits } from "../../../types/MeasurementUnits";
import { CheckIn } from "../../../types/CheckIn";
import { DateTime } from "luxon";

interface Props {
  weighIns: CheckIn[];
  measurementUnit: MeasurementUnits;
}

type FilterTypes = "day" | "week";

export const AverageChangeMetric: FC<Props> = ({
  weighIns,
  measurementUnit,
}) => {
  const [filter, setFilter] = useState<FilterTypes>("week");

  const handleChange = (
    event: React.ChangeEvent<{
      value: unknown;
    }>
  ) => {
    setFilter(event.target.value as FilterTypes);
  };

  const lastWeighIn = weighIns[weighIns.length - 1];
  const firstWeighIn = weighIns[0];
  const weightDifference = lastWeighIn.weight! - firstWeighIn.weight!;

  const dayDifference = DateTime.fromISO(firstWeighIn.date).diff(DateTime.fromISO(lastWeighIn.date), 'days');
  const weekDifference = DateTime.fromISO(firstWeighIn.date).diff(DateTime.fromISO(lastWeighIn.date), 'weeks');

  const weeks = weekDifference.weeks || 1
  const days = dayDifference.days + 1

  const averageChange =
    filter === "week" ? Math.abs(weightDifference) / Math.ceil(weeks) : weightDifference / days;

  return (
    <Box border="1px solid black" padding={2}>
      <Grid container direction="column">
        <Box padding={2}>
          <Grid item container justifyContent="center" spacing={5} alignItems="center">
            <Grid item>
              <Typography>Weight change per:</Typography>
            </Grid>
            <Grid item>
              <Select
                autoWidth
                value={filter}
                variant="filled"
                onChange={handleChange}
                style={{ width: "150px" }}
              >
                <MenuItem value="day">Day</MenuItem>
                <MenuItem value="week">Week</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Box>
        <Grid container item alignItems="center" spacing={2} justifyContent="center">
          <Grid item>
            <Typography variant="h3">
              {Math.abs(averageChange).toFixed(2)}
              {measurementUnit}{" "}
            </Typography>
          </Grid>
          <Grid item>
            {weightDifference > 0 && <ArrowDownwardIcon fontSize="large" />}
            {weightDifference < 0 && <ArrowUpwardIcon fontSize="large" />}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
