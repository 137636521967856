import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  Snackbar,
  IconButton,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import { FC, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Metrics } from "../../../types/Metric";
import { ToastProps } from "../../../types/Toast";
import { MeasurementUnits } from "../../../types/MeasurementUnits";
import { UserApi } from "api/user/userApi";
import { CheckIn } from "types/CheckIn";
import { DateTime } from "luxon";

interface Props {
  isOpen: boolean;
  onClose(): void;
  unit: MeasurementUnits;
  userId: number;
  userApi: UserApi;
  currentCheckInData?: CheckIn;
}
const defaultCheckInData: CheckIn = {
  date: DateTime.now().toFormat('yyyy-MM-dd')
};

export const CheckInModal: FC<Props> = ({ isOpen, onClose, userId, userApi, currentCheckInData = defaultCheckInData }) => {
  const queryClient = useQueryClient();
  const { data } = useQuery<Metrics>("metrics", () => userApi!.getMetrics(userId.toString()), { enabled: !!UserApi });

  const [toast, setToast] = useState<ToastProps>();

  const [date, setDate] = useState(currentCheckInData.date);
  const [maintenance, setMaintenance] = useState(currentCheckInData.maintenance ?? false);
  const [protein, setProtein] = useState(currentCheckInData.protein ?? "");
  const [fat, setFat] = useState(currentCheckInData.fat ?? "");
  const [carb, setCarb] = useState(currentCheckInData.carb ?? "");

  const dateKey = date.slice(0, 10);
  const nutritionId = currentCheckInData.nutritionId || data?.metricsPerDate[dateKey]?.nutrition?.nutritionId;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!userApi) {
      return;
    }

    const payload = {
      protein: Number(protein),
      fat: Number(fat),
      carb: Number(carb),
      userId,
      isMaintenance: maintenance,
      date: new Date(date).toJSON(),
    };

    const nutritionCall = async () => {
      if (nutritionId) {
        await userApi.updateNutrition(
          {
            ...payload,
            nutritionId
          }
        );
      } else {
        await userApi.createNutrition(payload);
      }
    };

    try {
      await nutritionCall();

      queryClient.invalidateQueries("metrics");

      setToast({
        message: "Check in created successfully",
        severity: "success",
      });

      onClose();
    } catch {
      setToast({
        message: "Error: Check in not added",
        severity: "error",
      });
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <Snackbar
        open={!!toast?.message}
        autoHideDuration={6000}
        onClose={() => setToast({ message: "" })}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <Alert
          onClose={() => setToast({ message: "" })}
          severity={toast?.severity}
        >
          {toast?.message}
        </Alert>
      </Snackbar>
      <Grid container justifyContent="space-between" alignItems="center">
        <DialogTitle id="form-dialog-title">Check in</DialogTitle>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <TextField
                disabled={!!currentCheckInData.nutritionId}
                onChange={(e) => setDate(e.currentTarget.value)}
                value={date}
                fullWidth
                type="date"
                label="Date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                id="protein"
                label="Protein"
                type="text"
                value={protein}
                onChange={(e) => {
                  if (!isNaN(Number(e.target.value))) {
                    setProtein(e.target.value);
                  }
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                id="carbs"
                label="Carbs"
                type="text"
                value={carb}
                onChange={(e) => {
                  if (!isNaN(Number(e.target.value))) {
                    setCarb(e.target.value);
                  }
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                id="fat"
                label="Fat"
                type="text"
                value={fat}
                onChange={(e) => {
                  if (!isNaN(Number(e.target.value))) {
                    setFat(e.target.value);
                  }
                }}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                label="maintenance"
                control={
                  <Checkbox
                    checked={maintenance}
                    onChange={(e) => setMaintenance(e.target.checked)}
                    name="maintenance"
                  />
                }
              />
            </Grid>
            <Grid item>
              <Button
                disabled={!fat || !carb || !protein}
                fullWidth
                color="primary"
                variant="contained"
                type="submit"
              >
                Check In
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
