import { FC, useState } from "react";
import {
  AppBar,
  Grid,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  Drawer,
  Menu,
  MenuItem,
  ListItemAvatar,
  Avatar,
  IconButton,
  Link,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useLogout } from "share/hooks/useLogout";
import { useHistory } from "react-router-dom";
import { ModalKeys } from "AppRoutes";

interface Props {
  isCurrentUserSelected: boolean
  setOpenModalKey(newKey?: ModalKeys): void;
}

export const AppHeader: FC<Props> = ({ isCurrentUserSelected, setOpenModalKey }) => {
  const { push } = useHistory();
  const handleLogout = useLogout();


  const [rightMenuAnchor, setRightMenuAnchor] = useState<HTMLElement | null>(null);

  return (
    <AppBar position="static">
      <Toolbar>
        <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
          <Grid item>
            <Link color="inherit" href="/">
              Home
            </Link>
          </Grid>
          <Grid item>
            <IconButton onClick={(e) => setRightMenuAnchor(e.currentTarget)}>
              <MenuIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>

      {/* chat thing */}
      <Drawer open={false}>
        <List style={{ width: "100vw" }}>
          <ListItem>
            <ListItemAvatar>
              <Avatar alt="user" src="/static/images/avatar/1.jpg" />
            </ListItemAvatar>
            <ListItemText
              primary="Gabe Pressman"
              secondary="This is a very serious message blah blah blah"
            />
          </ListItem>
        </List>
      </Drawer>
      <Menu
        id="simple-menu"
        anchorEl={rightMenuAnchor}
        keepMounted
        open={Boolean(rightMenuAnchor)}
        onClose={() => setRightMenuAnchor(null)}
      >
        <MenuItem onClick={() => push("/settings")}>Settings</MenuItem>
        <MenuItem onClick={handleLogout}>Log Out</MenuItem>
      </Menu>


    </AppBar>
  );
};
