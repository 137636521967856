import {
  Select,
  MenuItem,
  Grid,
  FormHelperText,
  Button,
  Snackbar,
} from "@material-ui/core";
import { UserApi } from "api/user/userApi";
import { FC, useState } from "react";
import { Metrics } from "types/Metric";
import { MeasurementUnits } from "types/MeasurementUnits";
import { User } from "api/user/types";
import { ToastProps } from "types/Toast";
import Alert from "@material-ui/lab/Alert";

interface Props {
  data: Metrics;
  userApi: UserApi;
}

export const SettingsForm: FC<Props> = ({ data, userApi }) => {
  const [toast, setToast] = useState<ToastProps>();
  const [measurementUnit, setMeasurementUnit] = useState<MeasurementUnits>(
    data.user.measurementUnit
  );

  const handleClick = async () => {
    const payload: User = { ...data.user, measurementUnit };
    const { error } = await userApi.updateUser(payload);

    if (error) {
      setToast({
        message:
          "We encountered an error updating settings.  Setting were not updated",
        severity: "error",
      });
    } else {
      setToast({
        message: "Settings successfully updated",
        severity: "success",
      });
    }
  };

  return (
    <Grid container justifyContent="center">
      <Snackbar
        open={!!toast?.message}
        autoHideDuration={6000}
        onClose={() => setToast({ message: "" })}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <Alert
          onClose={() => setToast({ message: "" })}
          severity={toast?.severity}
        >
          {toast?.message}
        </Alert>
      </Snackbar>
      <Grid container xs={6} spacing={2} direction="column">
        <Grid item container justifyContent="center" alignItems="center">
          <Grid item xs={3}>
            <FormHelperText>Display weight in:</FormHelperText>
          </Grid>
          <Grid item xs={9}>
            <Select
              fullWidth
              value={measurementUnit}
              onChange={(e) =>
                setMeasurementUnit(e.target.value as MeasurementUnits)
              }
            >
              <MenuItem value="KG">KG</MenuItem>
              <MenuItem value="LB">LBS</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid item container justifyContent="flex-end">
          <Button color="primary" variant="contained" onClick={handleClick}>
            Update
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
