import { CreateClientPayload, CreateUserPayload, User } from "api/user/types";
import { AxiosInstance } from "axios";
import { Nutrition } from "../../types/Macros";

interface WeightPayload {
  weight: number;
  date: string;
  userId: number;
}

type CreateNutritionPayload = Omit<Nutrition, "nutritionId">;

export class UserApi {
  axiosInstance: AxiosInstance;

  constructor(props: AxiosInstance) {
    this.axiosInstance = props;
  }
  //Nutrition
  async createNutrition(payload: CreateNutritionPayload) {
    await this.axiosInstance.post(
      `/users/${payload.userId}/nutrition`,
      payload
    );
  }

  async updateNutrition(payload: Nutrition) {
    await this.axiosInstance.put(
      `/users/${payload.userId}/nutrition/${payload.nutritionId}`,
      payload
    );
  }

  //Weight
  async createWeight(payload: WeightPayload) {
    await this.axiosInstance.post(`/users/${payload.userId}/weight`, payload);
  }

  async updateWeight(payload: WeightPayload & { weightId: number; }) {
    await this.axiosInstance.put(
      `users/${payload.userId}/weight/${payload.weightId}`,
      payload
    );
  }

  async deleteWeight(payload: WeightPayload & { weightId: number; }) {
    await this.axiosInstance.delete(
      `users/${payload.userId}/weight/${payload.weightId}`
    );
  }

  async getMetrics(userId: string) {
    const { data } = await this.axiosInstance.get(`/users/${userId}/metrics`);

    return data;
  }

  //Current
  async getCurrentCoachUser() {
    const { data } = await this.axiosInstance.get("/coach/current");

    return data;
  }

  async getCurrentUser() {
    const { data } = await this.axiosInstance.get(
      "/users/current-user/metrics"
    );

    return data;
  }

  async createUser(payload: CreateUserPayload) {
    try {
      const { data } = await this.axiosInstance.post(
        "/admin/users",
        payload
      );

      return data;
    } catch {
      return { error: true };
    }
  }

  async createClient(payload: CreateClientPayload) {
    try {
      const { data } = await this.axiosInstance.post(
        "/coach/users",
        payload
      );

      return data;
    } catch {
      return { error: true };
    }
  }

  async updateUser(payload: User) {
    try {
      const { data } = await this.axiosInstance.put(
        `/users/${payload.userId}`,
        payload
      );

      return data;
    } catch {
      return { error: true };
    }
  }
}
