import { UserApi } from "api/user/userApi";
import { AxiosInstance } from "axios";
import { Loading } from "components/Loading";
import { SettingsForm } from "pages/settings/components/SettingsForm";
import { FC } from "react";
import { useQuery } from "react-query";
import { Metrics } from "types/Metric";

interface Props {
  instance: AxiosInstance;
}

export const SettingsPage: FC<Props> = ({ instance }) => {
  const userApi = new UserApi(instance);

  const { data } = useQuery<Metrics>(["metrics"], () =>
    userApi.getCurrentUser()
  );

  if (!data) {
    return <Loading />;
  }

  return (
    <SettingsForm userApi={userApi} data={data}/>
  );
};
