import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@material-ui/core";
import { axiosInstance } from "api/service";
import { UserApi } from "api/user/userApi";
import { AppHeader } from "components/appHeader/AppHeader";
import { AddWeightModal } from "components/appHeader/components/AddWeightModal";
import { CheckInModal } from "components/appHeader/components/CheckInModal";
import { Loading } from "components/Loading";
import { CreateClient } from "pages/createClient";
import { SettingsPage } from "pages/settings/Settings";
import { useState } from "react";
import { useQuery } from "react-query";
import { Switch, Route } from "react-router-dom";
import { CheckIn } from "types/CheckIn";
import { Metrics } from "types/Metric";
import { CreateUser } from "./pages/createUser";
import { HomePage } from "./pages/home/Home";

export type ModalKeys = "checkIn" | "addWeight";

interface Props {
  token: string;
}

export const AppRoutes: React.FC<Props> = ({ token }) => {
  const { user } = useAuth0();

  const [openModalKey, setOpenModalKey] = useState<ModalKeys>();
  const [clientId, setClientId] = useState<number>();
  const [defaultCheckInData, setDefaultCheckInData] = useState<CheckIn>();

  const roles = user?.["http://nutrition-tracker-api.com/roles"];
  const isUserCoach = roles.includes("Coach");

  const instance = axiosInstance(token);
  const service = new UserApi(instance);


  const { data } = useQuery<Metrics>(["metrics"], () =>
    service.getCurrentUser()
  );

  if (!data) {
    return <Loading />;
  }

  const isCurrentUserSelected = !clientId || data.user.userId === clientId;

  return (
    <>
      <AppHeader setOpenModalKey={setOpenModalKey} isCurrentUserSelected={isCurrentUserSelected} />

      <Box padding={5}>
        <Switch>
          <Route
            path="/admin"
            render={() => <CreateUser instance={instance} />}
          />
          <Route
            path="/coach"
            render={() => <CreateClient instance={instance} />}
          />
          <Route
            path="/settings"
            render={() => <SettingsPage instance={instance} />}
          />
          <Route
            path="/"
            render={() => (
              <HomePage
                isCurrentUserSelected={isCurrentUserSelected}
                setOpenModalKey={setOpenModalKey}
                setDefaultCheckInData={setDefaultCheckInData}
                isUserCoach={isUserCoach}
                instance={instance}
                clientId={clientId}
                setClientId={setClientId}
              />
            )}
          />
        </Switch>

        <AddWeightModal
          key={openModalKey + "addWeight"}
          userApi={service}
          userId={clientId ?? data!.user.userId}
          currentCheckInData={defaultCheckInData}
          isOpen={openModalKey === "addWeight"}
          onClose={() => {
            setDefaultCheckInData(undefined);
            setOpenModalKey(undefined);
          }}

        />
        <CheckInModal
          key={openModalKey + "checkIn"}
          currentCheckInData={defaultCheckInData}
          userApi={service}
          userId={clientId ?? data!.user.userId}
          unit={data!.user.measurementUnit}
          isOpen={openModalKey === "checkIn"}
          onClose={() => {
            setDefaultCheckInData(undefined);
            setOpenModalKey(undefined);
          }}
        />
      </Box>
    </>
  );
};
