import { FC, useState } from "react";
import { CheckIn } from "../../../types/CheckIn";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { MeasurementUnits } from "../../../types/MeasurementUnits";
import CheckIcon from "@material-ui/icons/Check";
import { getCalories } from "../../../share/utils/getCalories";
import { ModalKeys } from "AppRoutes";

const daysInAWeek = 7;


interface EditableCellProps {
  onClick(): void;
  isCurrentUserSelected: boolean;
}

//TODO rethink this guy
const EditableCell: React.FC<EditableCellProps> = ({ children, onClick, isCurrentUserSelected }) => {
  if (isCurrentUserSelected) {
    return (
      <TableCell style={{ cursor: 'pointer' }} align='center' onClick={() => onClick()}>
        {children}
      </TableCell>
    );
  }

  return <TableCell align='center'>{children}</TableCell>;
};


interface Props {
  measurementUnit: MeasurementUnits;
  checkIns: CheckIn[];
  setOpenModalKey(newKey?: ModalKeys): void;
  setDefaultCheckInData(checkInData?: CheckIn): void;
  isCurrentUserSelected: boolean;
}

export const CheckInTable: FC<Props> = ({ checkIns, measurementUnit, isCurrentUserSelected, setOpenModalKey, setDefaultCheckInData }) => {
  const [showPartialList, setShowPartialList] = useState(true);

  const handleClick = () => {
    setShowPartialList(false);
  };

  const data = showPartialList ? checkIns.slice(0, daysInAWeek) : checkIns;
  const shouldShowButton = showPartialList && checkIns.length > daysInAWeek;

  const makeHandleOpenCheckinModal = (checkIn: CheckIn) => (modalKey: ModalKeys) => {
    setDefaultCheckInData(checkIn);
    setOpenModalKey(modalKey);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="center">Maintenance</TableCell>
              <TableCell width="20%" align="center">
                Weight
              </TableCell>
              <TableCell align="center">Calories</TableCell>
              <TableCell align="center">Protein&nbsp;(g)</TableCell>
              <TableCell align="center">Carbs&nbsp;(g)</TableCell>
              <TableCell align="center">Fat&nbsp;(g)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((entry) => {
              const { protein, carb, fat, weight, date, maintenance } = entry;
              const handleOpenCheckinModal = makeHandleOpenCheckinModal(entry);

              return (
                <TableRow key={date}>
                  <TableCell component="th" scope="row">
                    {date}
                  </TableCell>
                  <EditableCell isCurrentUserSelected={isCurrentUserSelected} onClick={() => handleOpenCheckinModal('checkIn')}>
                    {maintenance && <CheckIcon />}
                  </EditableCell>
                  <EditableCell
                    isCurrentUserSelected={isCurrentUserSelected}
                    onClick={() => {
                      handleOpenCheckinModal('addWeight');
                    }}
                  >
                    {weight && (
                      <div>
                        {weight} {measurementUnit}
                      </div>
                    )}
                  </EditableCell>
                  <EditableCell isCurrentUserSelected={isCurrentUserSelected} onClick={() => handleOpenCheckinModal('checkIn')}>
                    {getCalories({ protein, carb, fat })}
                  </EditableCell>
                  <EditableCell isCurrentUserSelected={isCurrentUserSelected} onClick={() => handleOpenCheckinModal('checkIn')}>
                    {protein}
                  </EditableCell>
                  <EditableCell isCurrentUserSelected={isCurrentUserSelected} onClick={() => handleOpenCheckinModal('checkIn')}>
                    {carb}
                  </EditableCell>
                  <EditableCell isCurrentUserSelected={isCurrentUserSelected} onClick={() => handleOpenCheckinModal('checkIn')}>
                    {fat}
                  </EditableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {shouldShowButton && (
        <Grid item container justifyContent="center">
          <Button onClick={handleClick} color="primary" variant="outlined">
            See more
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
