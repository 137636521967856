import "@fontsource/roboto";
import { AppRoutes } from "./AppRoutes";
import { BrowserRouter as Router } from "react-router-dom";
import "react-vis/dist/style.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { Auth0Provider } from "@auth0/auth0-react";
import { AuthWrapper } from "AuthWrapper";

const queryClient = new QueryClient();

const domain = process.env.REACT_APP_AUTH0_DOMAIN as string;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const scope = process.env.REACT_APP_AUTH0_SCOPE;

function App() {
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      audience={audience}
      scope={scope}
    >
      <QueryClientProvider client={queryClient}>
        <Router>
          <AuthWrapper>
            {(token) => <AppRoutes token={token} />}
          </AuthWrapper>
        </Router>
      </QueryClientProvider>
    </Auth0Provider>
  );
}

export default App;
